import { SectionLayout } from "../../components/shared/SectionLayout";

export const Main = () => {
  const buildersHighlightStyle = {
    backgroundImage: `url("images/Main/highlight1.png")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    display: "inline-block",
    padding: "0 10px",
  };
  const subcontractorsHighlightStyle = {
    backgroundImage: `url("images/Main/highlight2.png")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    display: "inline-block",
    padding: "0 10px",
  };
  return (
    <div
      style={{
        backgroundImage: `url("/images/Main/main-bg.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100vw",
      }}
    >
      <SectionLayout>
        <div class="container flex flex-col items-center mt-12 md:mt-0 space-y-8 md:space-y-0 md:flex-row md:content-center">
          <div class="md:w-1/2 space-y-6 mt-18 md:mt-0">
            <div class="flex h-24 md:justify-start justify-center">
              <img src="/icons/subbieLogo.svg" />
            </div>
            <div class="text-center md:text-left">
              <h1 class="text-3xl lg:text-5xl font-bold leading-snug">
                The phonebook made for{" "}
                <span style={buildersHighlightStyle}> builders</span> and
                <span style={subcontractorsHighlightStyle}>
                  {" "}
                  subcontractors.
                </span>
              </h1>
            </div>
            <div class="text-center md:text-left">
              <p class="text-xl lg:text-2xl font-medium">
                Find and book work with subcontractors and builders
              </p>
              <div class="flex md:justify-start justify-center pt-4">
                <a href="https://app.subbie.work/auth/register">
                  <img
                    src="/icons/registerNowIcon.svg"
                    alt="Register Now icon"
                    class="max-h-12 rounded-xl"
                  />
                </a>
              </div>
            </div>

            {/* <div class="flex flex-col items-center md:items-start">
              <p>Coming soon to</p>
              <img
                class="max-h-10"
                src="icons/ios-comingsoon.png"
                alt="Download on Appstore icon"
              />
            </div> */}
          </div>
          <div class="flex flex-col md:w-1/2 items-end space-y-6">
            <img
              src="images/Main/main-image-v2.svg"
              alt="Subbie Application Graphic"
            />
          </div>
        </div>
      </SectionLayout>
    </div>
  );
};
