import { SectionLayout } from "../../components/shared/SectionLayout";

export const Register = () => {
  const thisYear = new Date().getFullYear();
  return (
    <div
      style={{
        backgroundImage: `url("images/Register/register-bg.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <SectionLayout>
        <div class="container columns-2 flex flex-col items-center mx-auto space-y-0 md:flex-row">
          <div class="md:w-1/2 md:space-y-8 pb-6 md:pb-0">
            <img src="/images/Register/register-image.svg" />
          </div>
          <div class="md:w-1/2 space-y-12 h-full content-evenly md:pl-8">
            <div class="flex text-center md:text-left content-between">
              <p class="text-white text-xl lg:text-2xl ">
                <span
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    verticalAlign: "bottom",
                  }}
                >
                  <img
                    src="/icons/subbieLogo.svg"
                    alt="Subbie logo"
                    style={{
                      verticalAlign: "bottom",
                      height: "1.4em",
                      paddingRight: "0.25em",
                    }}
                  />
                </span>
                revolutionises how head contractors and subcontractors connect,
                turning individual networks into a powerful community hub.
              </p>
            </div>
            <div class="text-center md:text-left">
              <p class="text-white text-xl lg:text-2xl">
                Discover talent, showcase your skills, and build stronger, more
                successful projects.
              </p>
            </div>
            <div class="flex flex-col items-center md:items-start ">
              <div class="pb-6 md:pt-4">
                <a href="https://app.subbie.work/auth/register">
                  <img
                    src="/icons/registerNowIcon.svg"
                    class="h-[3rem] md:h-[4rem]"
                  />
                </a>
              </div>
              <img
                src="/icons/ios-comingsoon.png"
                alt="Coming soon to the App Store"
                class="h-[3rem]"
              />
            </div>
          </div>
        </div>
        <div class="text-center text-xs md:text-md lg:text-lg my-6 mt-16 md:mt-12 md:mb-0 md:mx-0">
          <p class="text-white">
            {" "}
            © {[thisYear]} Subbie. All rights reserved. No part of this
            application may be reproduced, distributed, or transmitted in any
            form or by any means, including photocopying, recording, or other
            electronic or mechanical methods, without the prior written
            permission of the publisher, except in the case of brief quotations
            embodied in critical reviews and certain other noncommercial uses
            permitted by copyright law.
          </p>
        </div>
      </SectionLayout>
    </div>
  );
};
