import { SectionLayout } from "../../components/shared/SectionLayout";
import React, { useRef, useState } from "react";

export const VideoPlayer = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setTimeout(() => {
        setIsHover(false);
      }, 1000);
      setIsPlaying(true);
    } else {
      video.pause();
      setIsHover(true);
      setIsPlaying(false);
    }
  };

  return (
    // <div class="flex justify-center aspect-w-16 aspect-h-9">
    //   <iframe
    //     width="1080"
    //     height="720"
    //     src="https://s3.ap-southeast-2.amazonaws.com/apse2.public.media.subbie/media/video/20240428-Subbie-Review_4x5-02.mov"
    //     frameborder="0"
    //     allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    //     allowfullscreen="true"
    //     class="rounded-xl drop-shadow-xl"
    //   ></iframe>
    // </div>
    // <div class="relative h-screen">
    //   <div class="flex justify-center">
    //     <h1 class="text-3xl lg:text-5xl font-bold leading-snug">
    //       Hear it from one of our users
    //     </h1>
    //   </div>
    //   <div>
    //     <a className="absolute top-[10%]">
    //       <img
    //         src="/images/VideoPlayer/videoPlayerBG.png"
    //         alt="iPhone Background"
    //       />
    //     </a>
    //   </div>
    //   <div class="flex justify-center ">
    //     <a className="absolute w-[20.7%] z-20">
    //       <img
    //         src="/images/VideoPlayer/phoneScreenshot.png"
    //         alt="iPhone Background"
    //       />
    //     </a>
    //     <div class="flex relative justify-center translate-y-[55%] aspect-w-16 aspect-h-9 z-30">
    //       <video class="w-[25%]" controls>
    //         <source
    //           src="https://s3.ap-southeast-2.amazonaws.com/apse2.public.media.subbie/media/video/20240428-Subbie-Review_4x5-02.mov"
    //           type="video/mp4"
    //         ></source>
    //       </video>
    //     </div>
    //   </div>
    // </div>
    <>
      <div className="flex justify-center">
        <h1 className="text-3xl lg:text-5xl font-bold leading-snug text-center mb-4 md:mb-6">
          Subbie in Action
        </h1>
      </div>
      <div className="relative h-[600px] md:h-[720px] lg:h-[850px] bg-[url('../public/images/VideoPlayer/videoPlayerBG.png')] bg-center bg-no-repeat py-1">
        <div className="flex justify-center mt-10">
          <a className="relative w-[240px] md:w-[300px] lg:w-[400px]  ">
            <img
              src="/images/VideoPlayer/phoneScreenshot.png"
              alt="Phone Screenshot"
              className="w-[100%]"
            />
            <div
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              className="absolute aspect-w-16 aspect-h-9  w-[212px] md:w-[266px] lg:w-[354px] top-[150px] md:top-[180px] lg:top-[245px] left-[14px] md:left-[17px] lg:left-[23px] z-30 "
            >
              <video ref={videoRef} className=" w-[100%]">
                <source
                  src="https://s3.ap-southeast-2.amazonaws.com/apse2.public.media.subbie/media/video/20240428-Subbie-Review_4x5-02.mov"
                  type="video/mp4"
                  width="100%"
                />
              </video>
              <button
                type="button"
                onClick={togglePlayPause}
                className={`absolute inset-0 flex items-center justify-center w-full h-full ${
                  isHover ? "bg-black bg-opacity-50" : ""
                }`}
              >
                {isPlaying ? (
                  <div
                    className={`bg-[#fff] rounded-full p-3 ${
                      isHover ? "" : "hidden"
                    }`}
                  >
                    <img src="/icons/pause.svg" class="h-[30px]" />
                  </div>
                ) : (
                  <div className="bg-[#fff] rounded-full p-3">
                    <img src="/icons/play.svg" class="h-[30px]" />
                  </div>
                )}
              </button>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};
