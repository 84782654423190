import { Main } from "./sections/Main";
import { Features } from "./sections/Features";
import { Register } from "./sections/Register";
import { Nav } from "./sections/Nav";
import { VideoPlayer } from "./sections/VideoPlayer";
import { Contact } from "./sections/Contact";

export const HomePage = () => {
  return (
    <div>
      <Nav />
      <Main />
      <VideoPlayer />
      <Features />
      <Register />
    </div>
  );
};
