import { SectionLayout } from "../../components/shared/SectionLayout";
import React, { useEffect, useState } from "react";

export const Nav = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <nav
      id="navBar"
      className={`fixed top-0 z-50 flex w-full px-[5%] items-center justify-between p-4 transition-colors duration-300 ${
        isScrolled ? "bg-white shadow" : "bg-transparent"
      }`}
    >
      <div class="flex items-center flex-shrink-0 text-black ">
        <div class="flex ">
          <a href="#top">
            <img
              src="/icons/subbieLogoMockUp2.svg"
              class="h-10 bg-black rounded-[25%]"
            />
          </a>
        </div>
      </div>

      <div class="flex items-center ">
        <div>
          <a
            href="https://app.subbie.work/auth/login"
            class="flex justify-end text-md px-4 py-3 leading-none rounded-lg bg-black text-white font-semibold hover:border-transparent hover:bg-gray-800 mr-2"
          >
            Login
          </a>
        </div>
        <div class="flex">
          <a
            href="https://www.instagram.com/subbie.work?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            class="flex items-center text-md px-2 leading-none rounded-full text-white hover:bg-gray-100 "
            target="_blank"
          >
            <img
              src="/icons/instagramCursiveLogo.png"
              class="h-10 pl-1  hidden md:inline"
            />
            <img
              src="/icons/instagramColourIcon.svg"
              class="h-10 inline pl-1 "
            />
          </a>
        </div>
      </div>
    </nav>
  );
};
