import React, { useState } from "react";
import { SectionLayout } from "../../components/shared/SectionLayout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Features = () => {
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div onClick={onClick} className={`arrow ${className}`}>
        <img src="/icons/arrowLeft.svg" class="h-[30px]" />
      </div>
    );
  };

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div onClick={onClick} className={`arrow ${className}`}>
        <img src="/icons/arrowRight.svg" class="h-[30px]" />
      </div>
    );
  }

  var settings = {
    dots: true,
    speed: 600,
    slidesToShow: 3.1,
    centerMode: false,
    infinite: false,
    accessibility: true,
    arrows: true,
    slidesToScroll: 1,
    nextArrow: <NextArrow to="next" />,
    prevArrow: <PrevArrow to="prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container px-[5%] py-[14%] md:py-[8%] md:h-fit mx-auto flex flex-col justify-center items-center p-4 ">
      <div className="container">
        <h2 className="flex md:flex-row items-center font-[550] text-3xl md:text-4xl lg:text-5xl justify-center md:justify-start">
          <span>How</span>
          <span className="inline-flex items-center">
            <img
              src="/icons/subbieLogo.svg"
              alt="Subbie logo"
              className="h-12 md:h-22 lg:h-24 mx-1 md:mx-4"
            />
          </span>
          <span>Works</span>
        </h2>
      </div>
      <div class="slider-container w-[90%] my-10">
        <Slider {...settings}>
          {[
            { image: "/images/Features/HowItWorks1.svg" },
            { image: "/images/Features/HowItWorks2.svg" },
            { image: "/images/Features/HowItWorks3.svg" },
            { image: "/images/Features/HowItWorks4.svg" },
          ].map((v, i) => {
            return (
              <div key={i}>
                <div class="highlight-card flex flex-col justify-center items-center">
                  <img src={v.image} />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};
